<template>
  <div>
    <div class="pa-4">
      <navbar-profile :items="items"></navbar-profile>
    </div>
    <div class="pa-2">
      <v-row no-gutters class="px-5" align="center">
        <v-col cols="12" md="4" class="py-2">
          <bullet title="Log Activity Klien" />
        </v-col>
      </v-row>
      <v-row no-gutters class="px-5">
        <v-col cols="12" md="8">
          <div class="mt-2 txt_search">
            <v-text-field
              v-model="find"
              hide-details
              solo
              flat
              background-color="#CFE1E1"
              class="rounded-lg"
              placeholder="Search for activity"
              prepend-inner-icon="mdi-magnify"
              @keyup="fetchData"
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="12" md="4" class="d-flex justify-end">
          <div class="mt-2 mr-2 d-flex align-center">
            <h4 class="mr-2">Show</h4>
            <v-menu
              v-model="menuLimit"
              elevation="0"
              transition="slide-y-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  min-width="60px"
                  max-width="60px"
                  outlined
                  depressed
                  class="text-capitalize"
                  v-bind="attrs"
                  v-on="on"
                >
                  <p class="ma-0 default_txt">{{ limit }}</p>
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-card class="popup_radius" outlined elevation="0">
                <v-list>
                  <v-list-item link @click="changeLimit(10)">
                    <v-list-item-title>10</v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click="changeLimit(50)">
                    <v-list-item-title>50</v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click="changeLimit(100)">
                    <v-list-item-title>100</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </div>
        </v-col>
      </v-row>
    </div>
    <div>
        <v-skeleton-loader
          class="pa-2 elevation-0"
          type="table-tbody"
          v-if="loading"
        ></v-skeleton-loader>
        <div v-if="!loading && dataSet">
          <v-data-table
            :headers="headers"
            :items="dataSet"
            hide-default-footer
            :page.sync="page"
            :items-per-page="limit"
            :single-select="false"
            class="elevation-0"
          >
            <template v-slot:[`item.created_at`]="{ item }">
              <div>
                <h4 class="not_bold">
                  {{  $date(item.created_at).format("DD MMM YYYY hh:mm:ss") }}
                </h4>
              </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <div class="d-flex">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      depressed
                      class="mr-2"
                      @click="lihatData(item)"
                    >
                      <v-icon small> mdi-eye </v-icon>
                    </v-btn>
                  </template>
                  <span>Lihat Detail</span>
                </v-tooltip>
              </div>
            </template>
          </v-data-table>
          <div class="text-center pa-3 d-flex justify-center">
            <v-pagination
              class="_80_w"
              color="#052633"
              v-model="page"
              @input="fetchData"
              :length="last_page"
            ></v-pagination>
          </div>
        </div>
    </div>
    <div class="pa-2">
      <dialog-medium
        :d_medium="d_create"
        :type="d_type"
        @close="d_create = false"
        :title="d_title"
        :inventory="inventory"
        :line="line"
        @refetch="fetchData"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import bullet from "../../components/Etc/bullet.vue";
import NavbarProfile from "../../components/navbarProfile/navbarProfile.vue";
import DialogMedium from '../Dialog/dialogMedium.vue';
export default {
  name: "logActivity",
  components: { bullet, NavbarProfile, DialogMedium },
  computed: {
    ...mapState({}),
  },
  data() {
    return {
      menuLimit: false,
      loading: false,
      dataSet: null,
      dialogAlert: false,
      d_create: false,
      d_small: false,
      d_type: "",
      d_title: "",
      inventory: "",
      line: "",
      find: "",
      page: 1,
      limit: 10,
      selected: null,
      last_page: null,
      total_page: null,
      sort: "-activity_log.created_at",
      filter: "",
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "Log Activity Klien",
          disabled: true,
          href: "#",
        },
      ],
      headers: [
        { text: "Nama", value: "nama" },
        { text: "Role", value: "log_name" },
        { text: "Deskripsi", value: "description" },
        { text: "Tanggal", value: "created_at" },
        { text: "Action", value: "actions", sortable: false },
      ],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    changeLimit(val) {
      this.limit = val;
      this.fetchData();
    },
    changeSort(item) {
      this.sort = item;
      this.fetchData();
    },
    changeFilter(item) {
      this.filter = item;
      this.fetchData();
    },
    fetchData() {
      this.loading = true;
      let data = {
        path: `sysadmin/log/activity-klien?page[size]=${this.limit}&page[number]=${this.page}&sort=${this.sort}&filter[pencarian]=${this.find}`,
      };
      this.$store
        .dispatch("client/getData", data)
        .then((data) => {
          console.log(data);
          this.dataSet = data.data;
          this.last_page = data.last_page;
          this.total_page = data.total;
          this.loading = false;
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },
    lihatData(item) {
      this.inventory = item;
      this.d_type = "v_activity";
      this.d_title = "Detail Activity";
      this.d_create = true;
      this.line = true;
    },
  },
};
</script>

<style scoped>
.v-pagination__item {
  box-shadow: none !important;
}
.v-pagination__navigation {
  box-shadow: none !important;
}
</style>
